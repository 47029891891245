import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
// import departmentImg from "../../assets/images/Icons/SVG/Ellipse 60.png";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";

import API from "../../Api/Api";
import { useFormik } from "formik";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useDispatch } from "react-redux";
import { DepartmentData } from "../../Redux/Slice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Department() {
  const [departmentList, setDepartmentList] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
const navigate =useNavigate();
  const getAllDepartmentList = () => {
    API.Department.GetAllDepartmentAPI()
      .then((response) => {
        if(response.data.statusCode ===200){
        console.log(response.data.data);
        setDepartmentList(response.data.data);
        }
      })
      .catch((err) => {
        if(err.response.status===403){
          navigate("/");
          }
        console.log(err);});
  };
  useEffect(() => {
    getAllDepartmentList();
  }, []);
  const validate = (values) => {
    console.log(values, "value");
    const errors = {};
    // const regex = /^[^\s].*$/;
    const spaceReg = /^\S.*\S$/;
    const emailReg = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (!values.dept_name) {
      errors.dept_name = "Please enter department name";
    } else if (!spaceReg.test(values.dept_name)) {
      errors.dept_name = "Cannot start & end with a space";
    }
    if (!values.email) {
      errors.email = "Please enter email";
    } else if (!emailReg.test(values.email)) {
      errors.email = "This is not a valid email address";
    }
    console.log("Erroes", errors);
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      dept_name: "",
      email: "",
    },
    onSubmit: (values) => {
      console.log(values, "hello values");
      CreateDepartment();
    },
    validate,
  });

  const CreateDepartment = () => {
    setLoading(true);
    API.Auth.CreateDepartment({
      data: {
        dept_name: formik.values.dept_name,
        email: formik.values.email,
      },
    })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          document.querySelector(".btn-close").click();
          setLoading(false);
          formik.resetForm();
          getAllDepartmentList();
        } else {
          toast.error(response?.data?.message);

          console.log(response);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const NeededData = (id, name, img, fullname) => {
    console.log(id, name, img, fullname, "data");
    const newdata = {
      id: id,
      DeptName: name,
      DeptImage: img ? img : "",
      fullname: fullname ? fullname : "-",
    };
    console.log(newdata, "newdatanewdatanewdata");
    dispatch(DepartmentData(newdata));
  };
  return (
    <AppLayout>
      <div className="main-content mt-3">
        <div className="page-content mb-5">
          <div className="row welcomeHeading">
            <div className="col-12">
              <h3 className="mb-0 fw-bold">Department</h3>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-6">
              <h4 className="textDarkBlack">Departments and their progress</h4>
            </div>
            <div className="col-lg-6">
              <div
                className="d-lg-flex justify-content-end"
                id="organizationModal"
              >
                {/* Button trigger modal */}
                <button
                  type="button"
                  className="btn sign-up-button-color text-white px-5"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  <i className="fa-solid fa-plus me-3"></i>
                  Add Department
                </button>
                {/* Modal */}
                <div
                  className="modal fade"
                  id="staticBackdrop"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                  style={{ zIndex: "999999" }}
                >
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header border-0">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            formik.resetForm();
                          }}
                        />
                      </div>
                      <div className="modal-body px-5">
                        <h3 class="textDarkBlack mb-4">Add department</h3>

                        <form>
                          <div className="form-group">
                            <label className="textLightGrey mb-1">
                              Department Name
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              placeholder="Enter department name"
                              id="exampleInputfirstname"
                              name="dept_name"
                              value={formik.values.dept_name}
                              onChange={formik.handleChange}
                            />
                          </div>
                          {formik.errors.dept_name &&
                          formik.touched.dept_name ? (
                            <div className="text-danger">
                              {formik.errors.dept_name}
                            </div>
                          ) : null}

                          <div className="mt-4 form-group">
                            <label className="textLightGrey mb-2">
                              Enter department owner's email
                            </label>
                            <input
                              type="email"
                              className="form-control mt-2"
                              placeholder="Enter work email address"
                              id=""
                              name="email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                            />
                          </div>
                          {formik.errors.email && formik.touched.email ? (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        
                          {/* <div className="form-group position-relative">
                            <label className="textLightGrey mb-1">
                              Share Link with Owner
                            </label>
                            <input
                              type="url"
                              className="form-control mt-2"
                              placeholder="https://e3yr9yher3d132erfv0987ytgfdxc@20eifj3fes"
                              id=""
                            />
                            <i class="fa-regular fa-copy copyIcon textBlueLight1"></i>
                          </div> */}
                          <button
                            type="submit"
                            className="btn sign-up-button-color text-white px-5 mt-3"
                            onClick={formik.handleSubmit}
                          >
                            {loading ? "Please wait..." : "Publish"}
                          </button>
                        </form>
                      </div>
                      <div className="modal-footer border-0"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {departmentList &&
              departmentList.map((item, index) => {
                return (
                  <div className="col-xxl-4 col-lg-6 col-12 mb-3" key={index}>
                    <NavLink
                      className="text-decoration-none"
                      to={`/department-details/${item?._id}`}
                      onClick={() =>
                        NeededData(
                          item?._id,
                          item?.dept_name,
                          item?.profileImageUrl,
                          item?.fullname
                        )
                      }
                    >
                      <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-2">
                        <div className="d-sm-flex align-items-center">
                          <img
                            src={
                              item?.profileImageUrl
                                ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                : departmentImg
                            }
                            className="me-3 Organization-img"
                          />
                          <div>
                            <h3 className="textDarkBlack mb-1 fw-bold msm">
                              {item?.dept_name}
                            </h3>
                            <p className="lightGrey mb-0">{item?.fullName}</p>
                          </div>
                        </div>
                        {/* <div class="progressP progress1 blue m-0">
                          <span class="progress-left">
                            <span class="progress-bar"></span>
                          </span>
                          <span class="progress-right">
                            <span class="progress-bar"></span>
                          </span>
                          <div class="progress-value  fw-bold">57%</div>
                        </div> */}
                        <div style={{ width: "100px", height: "100px" }}>
                          <CircularProgressbar
                            value={item?.dept_growth}
                            text={`${
                              item?.dept_growth > 0
                                ? item?.dept_growth.toFixed(2)
                                : item?.dept_growth
                            }%`}
                            strokeWidth={10}
                            styles={buildStyles({
                              rotation: 0.0,
                              strokeLinecap: "butt",
                              textSize: "20px",
                              className: "bold-text",
                              pathTransitionDuration: 0.5,
                              pathColor: `#6993ff, ${item?.dept_growth / 100})`,
                              textColor: "#6993ff",
                              trailColor: "#d6d6d6",
                              backgroundColor: "#3e98c7",
                            })}
                          />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
